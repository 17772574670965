<template>
  <section class="pd-20">
    <a-page-header class="pd-0 mb-10">
      <template #title>
        <h1 class="iblock relative top5">
          <img
            src="@/assets/images/dashboard/documentations/code.png"
            alt="img"
            width="30"
          />
          Documentações (Dev)
        </h1>
      </template>

      <template #extra>
        <a-button
          type="primary"
          ghost
          @click="openCreateNewDocumentationModal = true"
        >
          Nova documentação
        </a-button>
      </template>
    </a-page-header>

    <aTabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <aTabPane
        :disbled="documentation.loading"
        key="Finalizado"
        tab="Finalizado"
      />
      <aTabPane
        :disbled="documentation.loading"
        key="Em edição"
        tab="Em edição"
      />
      <aTabPane :disbled="documentation.loading" key="Revisão" tab="Revisão" />
    </aTabs>

    <aTable
      class="travel-table"
      :columns="documentationsTableColumns"
      :data-source="documentation.list"
      :loading="documentation.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="documentationTableChange"
    >
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div slot="technologies" slot-scope="record">
        <a-tag v-for="(tag, i) in JSON.parse(record.technologies)" :key="i">{{
          tag
        }}</a-tag>
      </div>

      <div slot="status" slot-scope="record">
        <a-tag class="edit" v-if="record.status === 'Em edição'">{{
          record.status
        }}</a-tag>
        <a-tag class="concluded" v-if="record.status === 'Finalizado'">{{
          record.status
        }}</a-tag>
        <a-tag class="review" v-if="record.status === 'Revisão'">{{
          record.status
        }}</a-tag>
      </div>

      <div slot="websites" slot-scope="record">
        <a-tag
          color="blue"
          v-for="(tag, i) in JSON.parse(record.websites)"
          :key="i"
          >{{ tag }}</a-tag
        >
      </div>

      <div slot="updated" slot-scope="record">
        {{ record.updated | formatDateTime }}
      </div>

      <div slot="created" slot-scope="record">
        {{ record.created | formatDateTime }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a
            class="edit"
            @click="$router.push(`/documentations/edit/${record.id}`)"
          >
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>

        <a-tooltip placement="top" title="Ver">
          <a
            class="view ml-10"
            @click="$router.push(`/documentations/view/${record.id}`)"
          >
            <a-icon slot="prefix" :type="'eye-svg'" />
          </a>
        </a-tooltip>
      </div>
    </aTable>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="documentation.pagination.page"
        :page-size.sync="documentation.pagination.perPage"
        :total="documentation.pagination.total"
        @change="changeDocumentationPage"
        @showSizeChange="documentationChangePageSize"
      >
      </a-pagination>
    </div>

    <a-modal
      v-model="openCreateNewDocumentationModal"
      title="Cadastrar documentação"
      :width="400"
      :footer="false"
      @cancel="openCreateNewDocumentationModal = false"
    >
      <CreateDocumentationModal
        v-if="openCreateNewDocumentationModal"
        @onCreateDocumentation="onCreateDocumentation"
      />
    </a-modal>
  </section>
</template>

<script>
import documentationsMixins from "@/components/documentations/mixins/documentationsMixins";
import formatThings from "@/mixins/general/formatThings";
import CreateDocumentationModal from "@/components/documentations/modals/CreateDocumentationModal.vue";

import formatInfoteraToHaya from "@/components/contracts/mixins/formatInfoteraToHaya";

export default {
  components: { CreateDocumentationModal },
  name: "DocumentationsList",
  mixins: [documentationsMixins, formatThings, formatInfoteraToHaya],
  data() {
    return {
      activeTab: "Finalizado",
      openCreateNewDocumentationModal: false,
    };
  },
  mounted() {
    this.documentation.filters.status.selected = ["Finalizado"];
    this.getDocumentations();
  },
  filters: {
    formatArrToStr(arr) {
      let theStr = "";
      if (arr !== undefined)
        JSON.parse(arr).forEach((item) => {
          theStr += `${item}, `;
        });
      return theStr.slice(0, -2);
    },
  },
  methods: {
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.documentation.filters.status.selected = [tab];
      this.getDocumentations();
    },
    onCreateDocumentation() {
      this.activeTab = "Em edição";
      this.documentation.filters.status.selected = ["Em edição"];
      this.getDocumentations();
      this.openCreateNewDocumentationModal = false;
    },
    async onClickRegisterMultipleContracts() {
      for (const row of this.ids) {
        await this.getInfotera2(row[0], row);
      }
    },
    async getInfotera2(id, row) {
      row;
      const tokenInfotera = await this.$http.post(`/infotera/tokenx`);

      console.log(tokenInfotera.data);
      let bookingInfotera = await this.$http.post(
        `/infotera/backoffice/booking`,
        {
          accessToken: tokenInfotera.data.accessToken,
          id,
        }
      );

      if (Object.keys(bookingInfotera.data).length) {
        this.infoteraToHaya(bookingInfotera.data.bookingDetail);
        this.infoteraFormattedData.infotera_json =
          bookingInfotera.data.bookingDetail;
        // this.createCustomer(id, bookingInfotera.data.bookingDetail);

        try {
          let theCustomer = await this.$http.post("/customer/createx", {
            user_id: 2,
            email: bookingInfotera.data.bookingDetail.contact.mail,
            person_type: "Pessoa Física",
            mobile_phone: bookingInfotera.data.bookingDetail.contact.telephone,
            first_name:
              bookingInfotera.data.bookingDetail.payments[0].client.name.split(
                " "
              )[0],
            last_name:
              bookingInfotera.data.bookingDetail.payments[0].client.name.replace(
                `${
                  bookingInfotera.data.bookingDetail.payments[0].client.name.split(
                    " "
                  )[0]
                } `,
                ""
              ),
            birthday:
              bookingInfotera.data.bookingDetail.payments[0].client.birth,
            status: "Ativo",
            cpf: bookingInfotera.data.bookingDetail.payments[0].client
              .documents[0].number,
          });

          console.log(theCustomer.data.id, bookingInfotera.data.bookingDetail);

          let contractData = {
            company_id: 1,
            company_branch_id: 2,
            customer_origin: "Lead (Infotera)",
            package_type: "Serviços",
            user: 2,
            id_infotera: id,
            status: "edit",
          };

          if (theCustomer.data.id) {
            contractData.customer_id = theCustomer.data.id;
          } else {
            contractData.customer_id = 310;
          }

          await this.$http
            .post("/contract-v2/createx", {
              ...contractData,
              payment_methods: JSON.stringify([]),
              contract_documents: JSON.stringify([]),
              ...this.infoteraFormattedData,
            })
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        } catch (response) {
          console.log("erro", bookingInfotera.data.bookingDetail, response);

          let contractData = {
            company_id: 1,
            company_branch_id: 2,
            customer_id: 310,
            customer_origin: "Lead (Infotera)",
            package_type: "Serviços",
            user: 2,
            id_infotera: id,
            status: "edit",
          };

          await this.$http
            .post("/contract-v2/create", {
              ...contractData,
              payment_methods: JSON.stringify([]),
              contract_documents: JSON.stringify([]),
              ...this.infoteraFormattedData,
            })
            .then(({ data }) => {
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.travel-table
  .ant-tag
    border: 1px solid #ddd !important
    color: #666 !important
    padding: 0px 5px
    font-size: 11px
    text-transform: none
    font-weight: 600
  .ant-tag.concluded
    color: #52c41a !important
    background: #f6ffed !important
  .ant-tag.edit
    color: #1890ff !important
    background: #e6f7ff !important
    border-color: #91d5ff !important
  .ant-tag.review
    color: #d5a000 !important
    background: #fffbee !important
    border-color: #ffde7c !important
</style>
