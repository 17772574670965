export default {
  data() {
    return {
      documentation: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          users: {
            selected: [],
          },
          status: {
            selected: [],
          },
          companies: {
            list: [],
            selected: [],
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      tempDocumentation: {},
      documentationsTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "Título",
          dataIndex: "title",
          key: "title",
          width: 350,
          sorter: true,
        },
        {
          title: "Websites",
          scopedSlots: { customRender: "websites" },
          width: 300,
        },
        {
          title: "Tecnologias",
          scopedSlots: { customRender: "technologies" },
          width: 300,
        },

        {
          title: "Última atualização",
          width: 150,
          scopedSlots: { customRender: "updated" },
        },
        {
          title: "Data de cadastro",
          width: 150,
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          width: 80,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 100,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    changeDocumentationPage(current) {
      this.documentation.pagination.page = current;
      this.getDocumentations();
    },
    documentationTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.documentation.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.documentation.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getDocumentations();
    },
    documentationChangePageSize(current, pageSize) {
      this.documentation.pagination.page = current;
      this.documentation.pagination.perPage = pageSize;
      this.getDocumentations();
    },
    documentationFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (this.documentation.filters.code) {
        filters += `&code=${this.documentation.filters.code}`;
        queryParams.set("code", `${this.documentation.filters.code}`);
      }

      if (this.documentation.filters.companies.selected.length > 0) {
        filters += `&company_id=${this.documentation.filters.companies.selected}`;
      }

      if (this.documentation.filters.status.selected.length > 0) {
        filters += `&status=${this.documentation.filters.status.selected}`;
      }

      if (this.documentation.filters.companyBranches.selected.length > 0) {
        filters += `&company_branch_id=${this.documentation.filters.companyBranches.selected}`;
      }

      if (this.documentation.filters.period.selected.length > 0) {
        filters += `&period=${this.documentation.filters.period.selected[0]}|${this.documentation.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.documentation.filters.period.selected[0]}|${this.documentation.filters.period.selected[1]}`
        );
      }

      if (this.documentation.filters.id) {
        filters += `&id=${this.documentation.filters.id}`;
      }

      filters += `&order=${this.documentation.filters.order}&order-by=${this.documentation.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async getDocumentationById(id) {
      try {
        const data = await this.$http.get(`/documentation/details?id=${id}`);
        this.documentation.details = data.data;
        this.tempDocumentation = data.data.raw;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.documentation.details = {};
      }
    },
    async getDocumentations(samePage) {
      this.documentation.loading = true;
      try {
        const { data } = await this.$http.get(
          `/documentation/list?page=${
            this.documentation.pagination.page
          }&per_page=${
            this.documentation.pagination.perPage
          }${this.documentationFilters()}`
        );

        this.documentation.list = data.data;
        this.documentation.meta = data.meta;
        this.documentation.pagination.page = data.meta.next_page;
        this.documentation.pagination.total = data.meta.total;

        if (!samePage) {
          this.documentation.pagination.page = 1;
        }
      } catch (e) {
        console.log(e);
        this.documentation.list = [];
        this.documentation.meta = {
          total: 0,
          total_available: "0,00",
          total_general: 0,
          total_pages: 0,
          total_spent: "0,00",
          total_value: "0,00",
        };
      } finally {
        this.documentation.loading = false;
      }
    },
  },
};
